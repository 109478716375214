import {datadogRum} from "@datadog/browser-rum";
import {assign} from "lodash";


type InitializeDataDogRumParam = Readonly<{
    attributes: Record<string, string>;
}>;

export function initializeDataDogRum({attributes}: InitializeDataDogRumParam) {
    
    const config = datadogConfiguration();
    
    if (config !== 'NOPE') {
        datadogRum.init({
            applicationId: 'f3bb6610-c8a9-4daa-b669-c4f25f1ff541',
            clientToken: 'pub53c278d2aede788830b6dbc9d5f6cae7',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: config.service,
            env: config.env,
            version: config.version,
            sessionSampleRate: config.sessionSampleRate,
            sessionReplaySampleRate: config.sessionReplaySampleRate,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow', //'mask-user-input',
            beforeSend: event => {
                event.context ??= {};
                assign(event.context, attributes);
                return true;
            },
            allowedTracingUrls: [
                window.location.origin,
            ]
        });
    }
}

export function setAnalyticsUser(email: string): void {
    datadogRum.setUser({email});    
}
export function clearAnalyticsUser(): void {
    datadogRum.clearUser();
}

export function setAnalyticsProperty(key: string, value: string | number | boolean | null) {
    datadogRum.setGlobalContextProperty(key, value);
}
export function setAnalyticsViewProperty(key: string, value: string | number | boolean | null) {
    datadogRum.setViewContextProperty(key, value);
}

type DataDogConfiguration = Readonly<{ 
    env: string;
    service: string;
    sessionReplaySampleRate: number;
    sessionSampleRate: number;
    version: string;
} | 'NOPE'>

function datadogConfiguration(): DataDogConfiguration {
    const env = process.env.REACT_APP_DD_RUM_ENV;
    const sampleRate = Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE);
    const sessionReplaySampleRate = Number(process.env.REACT_APP_DD_RUM_REPLAY_SAMPLE_RATE);
    const version = process.env.REACT_APP_DD_RUM_VERSION;
    
    if (!env) return 'NOPE';
    
    return {
        env,
        version: version ?? 'unknown',
        service: process.env.REACT_APP_DD_RUM_SERVICE || 'web',
        sessionSampleRate: !isNaN(sampleRate) ? sampleRate : 100,
        sessionReplaySampleRate: !isNaN(sessionReplaySampleRate) ? sessionReplaySampleRate : 100
    }
}

export const SECURE_ELEMENT_CLASS = "dd-privacy-mask"
