import {FieldValues, FormProvider, useForm} from "react-hook-form";
import {Configuration, ConfigurationKeys} from "../components/input/configuration/Configuration";
import {Button, Form} from "reactstrap";
import {useEffect, useMemo, useState} from "react";
import {zodResolver} from "@hookform/resolvers/zod";
import {generateSchema} from "./validation";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
	getAllConfigs,
	getCurrentPage,
	getNextPage,
	getPreviousPage,
	goToPreviousPage,
	gotToNextPage,
} from "./ProductConfigurationSlice";
import {ConfigurationEntryPageResolver} from "./ConfigurationEntryPageResolver";
import {configKey, defaultValues, pageConfigKeys} from "./model";
import {AddToCartButton} from "./inputs/AddToCartButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {usePersistentFormData} from "./UsePersistProductConfigData";
import {map} from "lodash/fp";
import "./ConfigurationEntryForm.css";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {SimpleToast} from "../components/input/utils/SimpleToast";

interface ConfigurationEntryFormProps {
	readonly configs: Configuration[];
	readonly value?: FieldValues;
	readonly onSubmit: (data: FieldValues) => void | Promise<void>;
	isReviewMode: boolean;
	siteId?: number;
}

export const ConfigurationEntryForm = ({
	                                       configs,
	                                       onSubmit,
	                                       value,
	                                       isReviewMode,
	                                       siteId
                                       }: ConfigurationEntryFormProps) => {
	const scrollAnchorId = "scroll-anchor-config";
	const dispatch = useAppDispatch();
	const currentPage = useAppSelector(getCurrentPage);
	const previousPage = useAppSelector(getPreviousPage);
	const nextPage = useAppSelector(getNextPage);
	const allConfigs = useAppSelector(getAllConfigs);
	const consumer = useAppSelector(selectCurrentConsumerUser);
	const [toastIsOpen, setToastIsOpen] = useState(false);


	const schema = useMemo(() => generateSchema(configs), [configs]);

	const form = useForm({
		resolver: zodResolver(schema),
		mode: 'onSubmit',
		shouldFocusError: true,
		reValidateMode: 'onChange',
		defaultValues: defaultValues(configs)
	});

	const {
		handleSubmit,
		watch,
		formState: {errors, isSubmitting},
		trigger,
		getValues,
		setValue,
	} = form;

	// Focuses the first form input of the current page if it has no value.
	const focusFirstInput = () => {
		if (!currentPage || !currentPage.groups[0]) {
			return;
		}
		const elementName = currentPage.groups[0].configs[0].configurationKey;
		const element =
			document.querySelector(`input[name="${elementName}"]`) as HTMLInputElement | null;
		if (element && (!element.value || element.value.length < 1)) {
			element.focus({preventScroll: true});
		}
	};

	// Scrolls the top of the configuration form on page change.
	// Exempt the first page so the stepper is seen at least once.
	// See the CSS class of the anchoring div for header offset.
	const scrollToAnchor = () => {
		const scrollAnchor = document.getElementById(scrollAnchorId);
		if (scrollAnchor && currentPage.sortOrder > 1) {
			scrollAnchor.scrollIntoView({behavior: "smooth"});
		}
	};

	const onNextClicked = async () => {
		const keysToValidate = pageConfigKeys(currentPage);
		const pageIsValid = await trigger(keysToValidate, {shouldFocus: true})
		if (pageIsValid) {
			dispatch(gotToNextPage());
			setToastIsOpen(false);
		} else {
			setToastIsOpen(true);
		}
	};

	const onPrevClick = () => {
		dispatch(goToPreviousPage());
	};

	const {
		storePersistentFormData,
		retrievePersistentFormData
	} = usePersistentFormData();

	// Scroll and focus on page change.
	useEffect(() => {
		scrollToAnchor();
		focusFirstInput();
	}, [currentPage]);

	function setFormValues(val: FieldValues | undefined) {
		if (val) {
			for (const key in val) {
				setValue(key, val[key], {
					shouldValidate: false,
					shouldDirty: false,
					shouldTouch: false
				});
			}
		}
	}

	useEffect(() => {
		// Load from persistent storage.
		const persistentFormData = retrievePersistentFormData({
			configurations: configs
		});

		if (persistentFormData) {
			setFormValues(persistentFormData);
			return;
		}

		setFormValues(value);

	}, [configs]);

	// Watch returns a new object on each call. Map to a stable constant instead.
	const formData = watch(map(configKey, allConfigs));
	useEffect(() => {
		storePersistentFormData({fieldValues: getValues()});
	}, [...formData]);

	// sets the consumer name field
	useEffect(() => {
		if (consumer && !getValues(ConfigurationKeys.NameOnAccount)) {
			setValue(ConfigurationKeys.NameOnAccount, `${consumer.firstName} ${consumer.lastName}`);
		}
	}, [consumer]);

	return (
		<FormProvider {...form}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div id={scrollAnchorId} className="scroll-anchor"/>
				<ConfigurationEntryPageResolver page={currentPage} isReviewMode={isReviewMode} siteId={siteId}/>
				{!isReviewMode &&
                    <div className="d-flex flex-row-reverse justify-content-between mt-4">
						{
							!nextPage
								? <AddToCartButton/>
								: <Button onClick={onNextClicked}>
									Next: {nextPage?.name}
									<FontAwesomeIcon icon={faChevronRight}/>
								</Button>
						}
						{previousPage &&
                            <Button onClick={onPrevClick}
                                    disabled={isSubmitting}
                                    color='link'
                                    className='text-decoration-none'
                            >
                                <FontAwesomeIcon icon={faChevronLeft}/>
								{' '}Back to: {previousPage.name}
                            </Button>
						}
                    </div>
				}
			</Form>
			<div className="floating-toast">
				<SimpleToast isOpen={toastIsOpen}
							 toastText="An error has occurred. Please review your inputs."
							 dismissToast={() => setToastIsOpen(false)}
							 isErrorToast={true}
				/>
			</div>
		</FormProvider>
	);
};
