
export interface ConfigurationRule {
    id: number,
    configurationDetailId: number,
    configurationRuleTypeId: number,
    referenceConfigurationDetailId?: number,
    referenceProductVariantId?: number,
    ruleFailedMessage?: string,
    configurationRuleType: ConfigurationRuleType,
    metaTag?: string,
    regexValue?: string,
    referenceRegexValue?: string
}


export interface ConfigurationRuleType {
    id: number,
    name: string,
    description: string
}

export enum RuleTypes {
    Matching = "Matching",
    Differing = "Differing",
    DisabledWhenValueExists = "DisabledWhenValueExists",
    PositiveMatch = "Positive Match",
    NegativeMatch = "Negative Match",
    Required = 'Required',
}