/**
 * This file contains the rule definitions
 */

import {ConfigurationRule, RuleTypes} from "../../components/input/configuration/rule/ConfigurationRule";
import {ruleValidationBuilder} from "./utils";
import {flow} from "lodash/fp";
import {isEmpty} from "lodash";

const ignoredMetaTags = [
    'phoneNumber'
];
const isIgnoredMetaTag = (rule: ConfigurationRule) =>
    rule.metaTag ?
        ignoredMetaTags.includes(rule.metaTag)
        : false;

const positiveMatch = ruleValidationBuilder(
    RuleTypes.PositiveMatch,
    (rule, value) =>
        isIgnoredMetaTag(rule) && !value
            ? true
            : new RegExp(rule.regexValue ?? '').test(value),
    (rule, value, referencedValue) => {
        if (!new RegExp(rule.referenceRegexValue!).test(referencedValue)) {
            return true;
        }
        return new RegExp(rule.regexValue!).test(value);

    }
);

const differing = ruleValidationBuilder(
    RuleTypes.Differing,
    (_rule, value) => !!value,
    (_rule, value, referencedValue) => value !== referencedValue,
);

const disabledWhenValueExists = ruleValidationBuilder(
    RuleTypes.DisabledWhenValueExists,
    () => true,
    (rule, value, referencedValue) => !referencedValue || !value
)

const matching = ruleValidationBuilder(
    RuleTypes.Matching,
    () => true,
    (_rule, value, referencedValue) => value === referencedValue,
);

const requiredRule = ruleValidationBuilder(
    RuleTypes.Required,
    (rule, value) => {
        return !isEmpty(value);
    },
    () => {
        return true;
    }
)

export const ruleValidation = flow(
    positiveMatch,
    differing,
    disabledWhenValueExists,
    matching,
    requiredRule,
    ([, , schema]) => schema
);