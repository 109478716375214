import {FieldValues, useController, useFormContext} from "react-hook-form";
import {ChangeEvent, useEffect, useState} from "react";
import cn from "classnames";
import {FormGroup, Input, InputGroup, InputGroupText} from "reactstrap";
import {FormInputProps} from "../components/forms/FormInputField";
import {RequiredAsterisk} from "../components/input/utils/RequiredAsterisk";
import {SECURE_ELEMENT_CLASS} from "../ops";

export function FormExpirationDateField<Schema extends FieldValues>(props: Omit<FormInputProps<Schema>, 'type' | 'placeholder' | 'autoComplete'>) {

    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('')

    const {
        setValue,
        trigger,
    } = useFormContext<Schema>();

    const {fieldState} = useController<Schema, typeof props.name>({
        control: props.control,
        name: props.name
    });

    useEffect(() => {
        // @ts-ignore
        setValue(props.name, {
            month,
            year
        }, {
            shouldValidate: false,
            shouldDirty: true,
            shouldTouch: true,
        });
    }, [month, year]);

    const handleChange = (setter: typeof setYear) =>
        ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
            setter(value.replace(/\D/, ''))
        }

    const monthClassName = cn('border-end-0', SECURE_ELEMENT_CLASS, {
        'border-danger': fieldState.invalid
    });
    const yearClassName = cn('border-start-0', SECURE_ELEMENT_CLASS, {
        'border-danger': fieldState.invalid
    });

    const slashClassName = cn('bg-white', {
        'border-danger': fieldState.invalid
    })

    return (
        <FormGroup>
            <div className="mb-2">
                {props.label}
                {props.requiredAsterisk && <RequiredAsterisk/>}
            </div>
            <InputGroup className="mb-1">
                <Input placeholder="MM"
                       className={monthClassName}
                       maxLength={2}
                       inputMode="numeric"
                       pattern="[0-9]{2}"
                       value={month}
                       onChange={handleChange(setMonth)}
                       autoComplete="cc-exp-month"
                />
                <InputGroupText className={slashClassName}>
                    /
                </InputGroupText>
                <Input placeholder="YY"
                       className={yearClassName}
                       maxLength={2}
                       pattern="[0-9]{2}"
                       inputMode="numeric"
                       value={year}
                       onChange={handleChange(setYear)}
                       onBlur={async () => {
                           if (fieldState.isDirty) {
                               await trigger(props.name);
                           }
                       }}
                       autoComplete="cc-exp-year"
                />
            </InputGroup>
            {fieldState.invalid &&
                <div className="text-danger fs-6">
                    {fieldState.error?.message}
                </div>}
        </FormGroup>
    );
}