import "./ProtectionSelector.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faClose} from "@fortawesome/free-solid-svg-icons";
import {Alert, Input, Label} from "reactstrap";
import {useEffect, useState} from "react";
import {ProductConfigurationInputProps} from "../ProductConfigurationInputProps";
import {Utils} from "../../../utils/Utils";
import classnames from "classnames";
import {ConfigurationOption} from "../../../components/input/configuration/ConfigurationOption";
import {useController} from "react-hook-form";
import {MediaQueryTypes, useMediaQuery} from "../../../app/hooks";
import {isConfigurationOption} from "../../../components/input/ProductInput";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {JsonOutput} from "../../JsonOutput";

export const ProtectionSelector = ({config}: ProductConfigurationInputProps) => {
	const [selectedOption, setSelectedOption] = useState<ConfigurationOption>();
	const options = config.configurationDetail.options ?? [];
	const isMobile = useMediaQuery(MediaQueryTypes.IS_VIEWPORT_XXL);
	const BENEFIT_LIST = [
		"Convenient Check Protection",
		"Up to $25000 Advancement of Funds",
		"Certified Resolution Specialist",
		"End2End Defense™ (32-Step Restoration)",
		"Online Identity Vault™ for Encrypted Data Storage",
		"Breach & Scam News Alert",
		"Identity Reports",
		"Personalized Dashboard",
		"Password Storage & Generator Tool"
	]

	// RHF integration.
	const {field: {onChange: onOptionChange, value: optionValue}, fieldState: {invalid, error}} = useController({
		name: config.configurationKey,
		defaultValue: undefined
	});

	// USE EFFECTS.

	useEffect(() => {
		if (!optionValue) {
			const defaultOption = getDefaultOption();
			if (defaultOption) {
				handleChange(defaultOption);
			}
		} else {
			const optionFromValue =
				options.find(o => o.value === optionValue);
			handleChange(optionFromValue);
		}
	}, [optionValue]);

	// UTILITIES.

	const getDefaultOption = () => {
		const defaultOptionIndex = options.findIndex(o => o.isDefault);
		if (defaultOptionIndex > -1) {
			return options[defaultOptionIndex];
		}
		return undefined;
	};

	const isSelectedOption = (option: ConfigurationOption) => {
		return (option.value === selectedOption?.value);
	};

	// HANDLERS.

	const handleChange = (option: ConfigurationOption | undefined) => {
		onOptionChange(option?.value);
		setSelectedOption(option);
	}

	const renderErrorIfNeeded = () => (
		invalid && <Alert color="danger" className="mt-4">{error?.message}</Alert>
	);
	
	// R1ENDERERS (DESKTOP).


	// Formats pricing info.
	const renderPriceForDesktop = (option: ConfigurationOption) => {
		if (isConfigurationOption(option) && option.addOn?.price) {
			return `${CurrencyFormatter.format(option.addOn.price)}`;
		}
		return "$0";
	};

	// Renders table row cells with their given benefit icons.
	const renderBenefitCellsForDesktop = (checkedBenefits: number[]) => {
		const rows = [];
		for (let i = 0; i < 9; i++) {
			let icon;
			if (checkedBenefits.includes(i)) {
				icon = <FontAwesomeIcon icon={faCheckCircle} className="text-success"/>
			} else {
				icon = <FontAwesomeIcon icon={faClose} className="text-danger"/>
			}
			rows.push(
				<tr>
					<td>{icon}</td>
				</tr>
			);
		}
		return rows;
	};

	// Renders the benefit columns as radio options.
	const renderOptionsForDesktop = () => {
		return [...options]
			.sort(Utils.sortBy("sortOrder"))
			.map((option, i) => {
				const classNames = classnames("col p-0 protection-option-col", {"protection-option-col-checked": isSelectedOption(option)})
				let checkedBenefits: number[] = [];

				// Determines which benefit icon to use.
				// The numeric array refers to indexes in BENEFIT_LIST.
				switch (option.value) {
					case "ez-shield-pro":
					case "ez-shield-business-pro":
						checkedBenefits = [0, 1, 2, 3, 4, 5, 6, 7, 8];
						break;
					case "ez-shield":
					case "ez-shield-business-standard":
						checkedBenefits = [0, 1, 2, 3];
						break;
					case "no-fraud-protection":
					default:
						break;
				}

				return (
					<div key={i} className={classNames}>
						<Label className="w-100 m-0">
							<table className="table table-sm table-striped table-borderless m-0">
								<thead>
								<tr>
									<th className="text-nowrap bg-secondary bg-opacity-50">{option.displayName}</th>
								</tr>
								</thead>
								<tbody className="protection-option-table-body">
								{renderBenefitCellsForDesktop(checkedBenefits)}
								<tr>
									<td>{renderPriceForDesktop(option)}</td>
								</tr>
								</tbody>
								<tfoot className="protection-option-table-foot">
								<tr>
									<td className="text-nowrap">
										<Input type="radio"
										       name={config.configurationKey}
										       checked={isSelectedOption(option)}
										       onChange={() => handleChange(option)}
										       className="m-2"
										/><br/>
										<span className="protection-option-label">Select<br/>{option.displayName}</span>
									</td>
								</tr>
								</tfoot>
							</table>
						</Label>
					</div>
				);
			});
	};

	// Renders rows for the benefit description column.
	const renderBenefitRowsForDesktop = () => {
		return (
			<>
				{BENEFIT_LIST.map((benefit) => {
					return (
						<tr>
							<td><small>{benefit}</small></td>
						</tr>
					);
				})}
			</>
		);
	};

	// Renders the control as a benefit matrix.
	const renderForDesktop = () => {
		return (
			<div className="container text-center">
				<div className="row flex-nowrap m-0">
					<div className="col-5 p-0">
						<table className="table table-sm table-striped table-borderless protection-col">
							<thead>
							<tr>
								<th className="bg-secondary bg-opacity-50">Benefits</th>
							</tr>
							</thead>
							<tbody className="text-nowrap">
							{renderBenefitRowsForDesktop()}
							<tr>
								<td>Price</td>
							</tr>
							</tbody>
						</table>
					</div>
					{renderOptionsForDesktop()}
				</div>
				{renderErrorIfNeeded()}		
			</div>
		);
	};

	// RENDERERS (MOBILE).

	// Formats pricing info.
	const renderPriceForMobile = (option: ConfigurationOption) => {
		if (isConfigurationOption(option) && option.addOn?.price) {
			return `(+${CurrencyFormatter.format(option.addOn.price)})`;
		}
		return "";
	};

	// Wrapper for each benefit item.
	const renderBenefitForMobile = (benefitText: string) => {
		const icon = <FontAwesomeIcon icon={faCheckCircle} className="text-success protection-icon-mobile"/>;
		return (
			<div className="protection-benefit-mobile">
				<small>{icon} {benefitText}</small>
			</div>
		);
	};

	// Renders the list of benefits for a given option.
	const renderBenefitListForMobile = (optionValue: string) => {
		let benefitList: string[] = [];
		switch (optionValue) {
			case "ez-shield-pro":
			case "ez-shield-business-pro":
				benefitList = BENEFIT_LIST;
				break;
			case "ez-shield":
			case "ez-shield-business-standard":
				benefitList = BENEFIT_LIST.slice(0, 4);
				break;
			case "no-fraud-protection":
			default:
				break;
		}

		return benefitList.map((benefitText) => {
			return renderBenefitForMobile(benefitText);
		});
	};

	// Renders the component as benefits lists within radio boxes.
	const renderForMobile = () => {
		return (
			<>
				{[...options]
					.sort(Utils.sortBy("sortOrder"))
					.map((option, i) => {
						const classNames = classnames("protection-option-mobile", {"protection-option-mobile-checked": isSelectedOption(option)})
						return (
							<Label key={i} className="w-100 m-0">
								<div className={classNames}>
									<Input type="radio"
									       name={config.configurationKey}
									       checked={isSelectedOption(option)}
									       onChange={() => handleChange(option)}
									/>
									<span>{option.displayName} {renderPriceForMobile(option)}</span>
									{renderBenefitListForMobile(option.value)}
								</div>
							</Label>
						);
					})
				}

				{renderErrorIfNeeded() }
			</>
		);
	};

	// OUTPUT.

	return (
		<>
			{isMobile ? renderForMobile() : renderForDesktop()}
		</>
	);
};
