import {z} from "zod";
import {curry, flow} from "lodash/fp";
import {Configuration} from "../../components/input/configuration/Configuration";
import {ConfigurationRule} from "../../components/input/configuration/rule/ConfigurationRule";
import {InputTypeName} from "../../components/input/InputType";
import {ruleValidation} from "./rules";
import {ConfigurationValidationBuilder} from "./types";
import {addressSchema} from "../../addresses/AddressSchema";


export function generateSchema(configs: readonly Configuration[]) {
    const baseSchema = configs.reduce((schema, config) => {
        return schema.extend({
            [config.configurationKey]: toFieldSchema(config)
        });
    }, z.object({}));

    return configs
        .reduce((schema: z.Schema, config, _idx, allConfigs) => {
            return buildSchema([
                config,
                allConfigs,
                schema
            ])
        }, baseSchema)
}

const handleRules = curry(
    (allConfigs: readonly Configuration[],
     schema: z.Schema,
     rule: ConfigurationRule) =>
        ruleValidation([
            rule,
            allConfigs,
            schema,
        ]));

const rulesSchema: ConfigurationValidationBuilder =
    ([config, allConfigs, schema]) =>
        [
            config,
            allConfigs,
            config.rules
                .reduce(handleRules(allConfigs), schema)
        ];

const requiredSchema: ConfigurationValidationBuilder = 
    ([config, allConfigs, schema]) => [
        config,
        allConfigs,
        config.configurationDetail.isRequired
            ? schema.refine(data => !!data[config.configurationKey], {message: 'Required', path: [config.configurationKey]})
            : schema
    ];

/**
 * Adding the rules first allows the rules to take precedence
 */
const buildSchema = flow(
    rulesSchema,
    requiredSchema,
    ([, , schema]) => schema
);


function toFieldSchema(config: Configuration) {
    switch (config.inputType.name as InputTypeName) {
        case 'Address':
            return addressSchema;

        default:
            return z.coerce.string().optional();
    }
}