import {Control, Controller, ControllerRenderProps, FieldPath, FieldValues, useFormContext} from "react-hook-form";
import React, {ChangeEvent, PropsWithChildren} from "react";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import classnames from "classnames";
import {RequiredAsterisk} from "../input/utils/RequiredAsterisk";
import {curry} from "lodash";
import {includes} from "lodash/fp";
import {SECURE_ELEMENT_CLASS} from "../../ops";

type InputType = "text" | "email" | "tel" | "radio" | "select" | "checkbox" | "number" | "hidden" | 'search' | 'url' | 'password';
type InputMode = "text" | "email" | "tel" | "search" | "url" | "none" | "numeric" | "decimal";
type FormInputParseValue = (value: any) => any | Promise<any>;
type FormInputOnChange = (value: any | undefined) => void | Promise<void>;
type FormInputOnBlurHandler = (value: any | undefined) => void | Promise<void>;

export interface FormInputProps<Type extends FieldValues> {
	readonly control?: Control<Type>;
	readonly name: FieldPath<Type>;
	readonly label: string;
	readonly disabled?: boolean;
	readonly type?: InputType;
	readonly placeholder?: string;
	readonly parseValue?: FormInputParseValue,
	readonly onChange?: FormInputOnChange,
	readonly valid?: boolean;
	readonly requiredAsterisk?: boolean;
	readonly className?: string;
	readonly labelClassName?: string;
	readonly autoComplete?: string;
	readonly readOnly?: boolean;
	readonly plaintext?: boolean;
	readonly defaultChecked?: boolean;
	readonly defaultValue?: any;
	readonly onFocus?: any;
	readonly onBlur?: FormInputOnBlurHandler;
	readonly trimOnBlur?: boolean;
	readonly inputMode?: InputMode;
	readonly isSecure?: boolean;
}

export function FormInputField<Type extends FieldValues>(
	{
		control,
		label,
		name,
		placeholder,
		valid,
		className: _className,
		labelClassName,
		children,
		autoComplete,
		defaultValue,
		defaultChecked = false,
		disabled = false,
		type = 'text',
		inputMode = type === 'number' ? 'numeric' : 'text',
		readOnly = false,
		plaintext = false,
		requiredAsterisk = false,
		parseValue = (value) => value,
		onChange = () => void 0,
		onFocus,
		onBlur = () => void 0,
		trimOnBlur = true,
		isSecure = false
	}: FormInputProps<Type> & PropsWithChildren) {

	const {setValue} = useFormContext();
	const labelFirst = type !== "radio" && type !== "checkbox";
	const className = classnames(
		_className,
		{"d-none": type === "hidden"}
	);
	const labelClassnames = classnames(labelClassName, {"ms-2": !labelFirst});
	const inputClassName = classnames(
		{
			[SECURE_ELEMENT_CLASS]: isSecure
		}
	);

	const renderLabel = () => {
		return <Label for={name} className={labelClassnames}>
			{label}
			{requiredAsterisk && <RequiredAsterisk/>}
		</Label>;
	};

	return (
		<FormGroup className={className}>
			{labelFirst && renderLabel()}
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={
					({field: {ref, ...field}, fieldState}) =>
						(
							<>
								<Input id={name}
								       type={type}
								       inputMode={inputMode}
								       invalid={fieldState.invalid}
								       valid={valid}
								       placeholder={placeholder}
								       autoComplete={autoComplete}
								       disabled={disabled}
									   innerRef={ref}
									   {...field}
								       readOnly={readOnly}
								       plaintext={plaintext}
								       defaultValue={defaultValue}
								       defaultChecked={defaultChecked}
									   className={inputClassName}
								       onChange={handleOnChange(type, parseValue, onChange, field)}
								       onKeyDown={handleOnKeyDown(type, inputMode)}
								       onFocus={onFocus}
								       onBlur={e => {
									       if (trimOnBlur) {
										       setValue(name, e.target.value.trim() as any, {
											       shouldValidate: false,
											       shouldDirty: false,
											       shouldTouch: false,
										       });
									       }
									       field.onBlur();
									       onBlur(e.target.value);
								       }}
								>
									{children}
								</Input>
								{fieldState.invalid
									&& <FormFeedback>{fieldState.error?.message}</FormFeedback>}
							</>
						)
				}/>
			{!labelFirst && renderLabel()}
		</FormGroup>
	);
}

const handleOnChange = curry(async function <Type extends FieldValues>(
	type: InputType,
	parseValue: FormInputParseValue,
	onChange: FormInputOnChange,
	field: ControllerRenderProps<Type>,
	{target}: ChangeEvent<HTMLInputElement>) {

	// save selection location to restore later
	const {
		selectionStart,
		selectionEnd,
	} = target;
	
	let value: undefined;
	switch (type) {
		case "checkbox":
			value = await parseValue(target.checked);
			break;
		default:
			value = await parseValue(target.value);
	}

	field.onChange(value);
	if (onChange.length > 0){
		await onChange(value);
	}
	
	// restore selection
	if (includes(type, SELECTABLE_INPUT_TYPES)) {
		target.selectionStart = selectionStart;
		target.selectionEnd = selectionEnd;
	}
});

// Prevent non-numeric characters in number inputs.
const handleOnKeyDown = curry((type: InputType, mode: InputMode, e: React.KeyboardEvent<HTMLInputElement>) => {
	if (type === "number" || mode === 'numeric') {
		if (e.ctrlKey || e.metaKey || e.key.length > 1) {
			return;
		}
		if (!/^\d$/.test(e.key)) {
			e.preventDefault();
		}
	}
});

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/selectionStart
const SELECTABLE_INPUT_TYPES: readonly InputType[] = [
	'text',
	'tel',
	'url',
	'search',
	'password'
]