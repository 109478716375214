import './OrderGrid.css';
import {OrderVm} from "../../order/Order";
import {Button, Table} from "reactstrap";
import {ActionButton, GridAction, SortButton, SortState} from "../../../utils/Grid";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {DateTime} from "luxon";
import {useAppDispatch, useAppSelector, useElementIsOnScreen} from "../../../app/hooks";
import {
	canFetchMoreOrders,
	changeSearchCriteria,
	fetchMoreOrders,
	fetchOrders,
	isFetchingOrders,
	selectOrders,
	selectOrderSearchCriteria
} from "../../order/OrderSlice";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

const defaultSort: SortState<OrderVm> = {
	column: 'timeCreated',
	serverColumn: 'o.TimeCreated',
	direction: 'desc',
}

interface OrderGridProps {
	search: string
	actions?: GridAction<OrderVm>[]
}

export const OrderGrid = (props: OrderGridProps) => {
	const dispatch = useAppDispatch();
	const orders = useAppSelector(selectOrders);
	const moreOrdersAvailable = useAppSelector(canFetchMoreOrders);
	const isLoading = useAppSelector(isFetchingOrders);
	const ordersStatus = useAppSelector(state => state.orders.status);
	const criteria = useAppSelector(selectOrderSearchCriteria);

	const [sort, setSort] = useState<SortState<OrderVm>>(defaultSort);
	const lastOrder = orders ? orders[orders.length - 1] : undefined;
	const [ref, isVisible] = useElementIsOnScreen([lastOrder]);

	useEffect(() => {
		if (ordersStatus === 'idle' && criteria.statusIds) {
			dispatch(fetchOrders({
				criteria, paging: {
					sortKey: sort.serverColumn ?? sort.column,
					sortDirection: sort.direction
				}
			}));
		}
	}, [criteria, dispatch, ordersStatus, sort]);

	useEffect(() => {
		dispatch(changeSearchCriteria())
	}, [criteria, dispatch, sort]);

	useEffect(() => {
		if (isVisible && moreOrdersAvailable) {
			dispatch(fetchMoreOrders({
				criteria, paging: {
					lastId: lastOrder?.id,
					lastValue: lastOrder?.[sort.column]?.toString() ?? '',
					sortKey: sort.serverColumn ?? sort.column,
					sortDirection: sort.direction,
				}
			}));
		}
	}, [isVisible, dispatch, lastOrder, criteria, moreOrdersAvailable, sort.column, sort.direction, sort.serverColumn]);

	const filteredOrders = [...orders ?? []]?.filter(o =>
		o.id.toString().includes(props.search) ||
		o.status.toLowerCase().includes(props.search) ||
		(o.firstName + ' ' + o.lastName).toLowerCase().includes(props.search) ||
		o.customerEmail.toLowerCase().includes(props.search) ||
		o.routingNumber?.toString().includes(props.search) ||
		o.accountNumber?.toString().includes(props.search)
	)

	const OrderRow = (order: OrderVm, ix: number) => {
		const isRefRow = ix === (filteredOrders.length - 5);
		return (
			<tr key={order.id} ref={isRefRow ? ref : null}>
				<td>
					<Link to={`order/${order.id}`}>{order.id}</Link>
				</td>
				<td>{DateTime.fromISO(order.timeCreatedLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
				<td>{order.siteName}</td>
				<td>{order.status}</td>
				<td>
					<Link to={`consumer/${order.consumerId}`}>{order.consumerId}</Link>
				</td>
				<td>{order.customerEmail}</td>
				<td>{order.firstName}</td>
				<td>{order.lastName}</td>
				<td>{order.routingNumber}</td>
				<td>{order.accountNumber}</td>
				{
					props.actions?.length &&
                    <td>
						{
							props.actions?.map((action, i) => <ActionButton key={i} action={action} entity={order}/>)
						}
                    </td>
				}
			</tr>
		)
	}

	const HeaderRow = () => (
		<tr>
			<th>
				<SortButton column='id' serverColumn='o.Id' sortState={sort} sortStateChanged={setSort}>
					Order #
				</SortButton>
			</th>
			<th>
				<SortButton column='timeCreated' serverColumn='o.TimeCreated' sortState={sort} sortStateChanged={setSort}>
					Order Date
				</SortButton>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Storefront
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Status
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Cust. Id
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Email
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					First Name
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Last Name
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					ABAs
				</Button>
			</th>
			<th>
				<Button color='link' className='shadow-none text-dark opacity-100' disabled={true}>
					Account Number
				</Button>
			</th>
			{
				props.actions?.length &&
                <th>Actions</th>
			}
		</tr>
	)

	return isLoading ?
		<LoadingSpinner/> :
		filteredOrders?.length ?
			<div className="orders-grid-table-container">
				<Table striped bordered hover id='OrdersGrid'>
					<thead>{HeaderRow()}</thead>
					<tbody>
					{
						filteredOrders?.map(OrderRow)
					}
					</tbody>
				</Table>
			</div> :
			<span>Sorry, no orders found. Change your search and try again.</span>
}
