import "./SymbolSelector.css"
import {Button, Input, Label} from "reactstrap";
import {ConfigurationOption} from "../../../components/input/configuration/ConfigurationOption";
import {useController} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {SymbolSelectorModal} from "./SymbolSelectorModal";
import classnames from "classnames";
import {ProductConfigurationInputProps} from "../ProductConfigurationInputProps";
import {Utils} from "../../../utils/Utils";
import {some} from "lodash/fp";
import cn from "classnames";
import {MediaQueryTypes, useMediaQuery} from "../../../app/hooks";
import {SymbolSelectorPreview} from "./SymbolSelectorPreview";

export const SymbolSelector = ({config}: ProductConfigurationInputProps) => {
	const [selectedOption, setSelectedOption] = useState<ConfigurationOption>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const options = config.configurationDetail.options ?? [];

	const [parentSelection, setParentSelection] = useState<string | undefined>('');
	
	// RHF integration.

	const {field: {onChange: onChildChange, value}} = useController({
		name: config.configurationKey,
	});

	// USE EFFECTS.

	useEffect(() => {
		if (!parentSelection) {
			const defaultParentOption = getDefaultParentOption();
			setParentSelection(defaultParentOption?.value);
			setSelectedOption(defaultParentOption);
		} else {
			const optionFromValue = options.find(o => o.value === parentSelection);
			setParentSelection(optionFromValue?.value);
			setSelectedOption(optionFromValue);
		}
	}, [parentSelection]);

	// UTILITIES.

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const hasChildOptions = (option: ConfigurationOption) => {
		return option.childConfigurationOptions ? option.childConfigurationOptions.length > 0 : false;
	};

	const isSelectedParentOption = (option: ConfigurationOption) => {
		return (option.value === value)
			|| some(o => o.value === value.toString(), option.childConfigurationOptions);
	};

	const getDefaultParentOption = () => {
		const defaultOptionIndex = options.findIndex(o => o.isDefault);
		if (defaultOptionIndex > -1) {
			return options[defaultOptionIndex];
		}
		return undefined;
	};

	// HANDLERS.

	const handleParentChange = (option: ConfigurationOption) => {
		if (hasChildOptions(option)) {
			setSelectedOption(option)
			setIsModalOpen(true);
		}
		else {
			onChildChange(option.value.toString());
		}
	};

	const handleButtonClick = (option: ConfigurationOption) => {
		setSelectedOption(option);
		setIsModalOpen(true);
	};

	// Gets passed down to the modal for callback.
	const handleChildOptionClick = (option: ConfigurationOption) => {
		setIsModalOpen(false);
		onChildChange(option.value.toString());
	}

	// RENDERERS.

	// Renders top-level options as radio button boxes.
	const renderOptions = () => {
		return [...options]
			.sort(Utils.sortBy("sortOrder"))
			.map((option, index) => {
				const classNames = classnames("boxed-radio-option", {"boxed-radio-option-checked": isSelectedParentOption(option)})
				return (
					<div key={index} className={classNames}>
						<Label>
							<Input type="radio"
							       name={config.configurationKey}
							       checked={isSelectedParentOption(option)}
							       onChange={() => handleParentChange(option)}
							/>
							<span>
								{option.displayName}
								{renderPriceModification(option)}
							</span>
							{renderOptionButton(option)}
						</Label>
					</div>
				);
			});
	};

	// Renders a button if the given option has child options.
	const renderOptionButton = (option: ConfigurationOption) => {
		const hasChildOptions =
			option.childConfigurationOptions ? option.childConfigurationOptions.length > 0 : false;

		if (hasChildOptions) {
			return (
				<Button onClick={() => handleButtonClick(option)} className="btn-sm">
					{getButtonText(option)}
				</Button>
			);
		}
	};

	const isSmallViewPoint = useMediaQuery(MediaQueryTypes.IS_VIEWPORT_SM);
	const isLargeViewPoint = useMediaQuery(MediaQueryTypes.IS_VIEWPORT_LG);

	const getButtonText = (option: ConfigurationOption) => {
		if (isSmallViewPoint) {
			return 'Choose'
		}
		if (isMobile) {
			return 'Choose a ' + option.displayName;
		}
		if (isLargeViewPoint) {
			return 'Choose'
		}
		return 'Choose a ' + option.displayName;
	}

	const renderPriceModification = (option: ConfigurationOption) => {
		if (option.priceModification) {
			return <> (+${option.priceModification})</>
		}
	};

	const renderLabel = () => {
		return (
			<Label for={config.configurationKey}>{config.label}</Label>
		);
	};

	const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

	const containerClass = cn(
		isMobile ? '' : 'd-flex d-flex-row',
	);

	const selectorClass = cn(
		isMobile ? '' : 'col-6',
	);

	return (
		<>
			{renderLabel()}
			<div className={containerClass}>
				<div className={selectorClass}>
					{renderOptions()}
					<SymbolSelectorModal isOpen={isModalOpen}
										 toggle={toggleModal}
										 title={"Choose a " + selectedOption?.displayName}
										 options={selectedOption?.childConfigurationOptions ?? []}
										 onOptionClick={handleChildOptionClick}
					/>
				</div>
				<div>
					<SymbolSelectorPreview config={config} hideNoSelectionMessage={true} />
				</div>
			</div>
		</>
	);
};
