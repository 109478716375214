import { Component } from "./component/Component";
import { Configuration } from "./configuration/Configuration";
import { ComponentOption } from "./component/ComponentOption";
import { ConfigurationOption } from "./configuration/ConfigurationOption";

export type ProductInput = Component | Configuration;
export type ProductInputOption = ComponentOption | ConfigurationOption;
 

// the `is` keyword for this return type indicates that this function narrows the provided ProductInput's type down into Component/Configuration
export const isConfiguration = (productInput: ProductInput): productInput is Configuration =>
  'configurationKey' in productInput;

export const isConfigurationOption = (option?: ProductInputOption): option is ConfigurationOption =>
  !!(option && ('childConfigurationOptions' in option));

export const getProductInputLabel = (productInput: ProductInput, isInEditMode: boolean) => {
  if (!isConfiguration(productInput)) return productInput.label;
  
  const actionLabel = productInput.configurationDetail.actionLabel ?? productInput.configurationDetail.label;
  return (isInEditMode ? actionLabel : productInput.configurationDetail.label) ?? "";
}

export const getProductInputOptions = (productInput: ProductInput): ProductInputOption[] | undefined =>
  isConfiguration(productInput) ?
    productInput.configurationDetail.options :
    productInput.componentOptions;

export const isProductInputRequired = (productInput: ProductInput) =>
  isConfiguration(productInput) ?
    productInput.configurationDetail.isRequired :
    true; // all components are required

export const isProductInputSecure = (productInput?: ProductInput) =>
  productInput && isConfiguration(productInput) ? productInput.isSecure : false; // all components are not secure

export const getProductInputKey = (productInput: ProductInput) =>
  isConfiguration(productInput) ? productInput.configurationKey : productInput.name.replaceAll(/\W/g, '');

export const getProductInputOptionDisplayName = (option: ProductInputOption) =>
  isConfigurationOption(option) ? option.displayName : option.value;

export const getProductInputOptionName = (option?: ProductInputOption) =>
  option && isConfigurationOption(option) ? option.name : option?.value ?? "";