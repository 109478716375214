import React from "react";
import cn from "classnames";
import {DisplayImage} from "../../../components/image/display/DisplayImage";
import {useFormContext} from "react-hook-form";
import './SymbolSelectorPreview.css';
import {Configuration} from "../../../components/input/configuration/Configuration";
import {configDefaultOption} from "../../model";
import {ReviewPanel} from "../../review/ReviewPanel";

interface SymbolSelectorPreviewProps {
	displayInfo?: boolean;
	readonly config: Configuration;
	readonly hideNoSelectionMessage: boolean;
}

export function SymbolSelectorPreview({
	                                      displayInfo = true,
	                                      config,
	                                      hideNoSelectionMessage
                                      }: SymbolSelectorPreviewProps) {
	const {getValues} = useFormContext();

	const previewInfo = () => {
		return "The image previewed above will appear at the top left of your check next to your Personal Details. You will see a full preview image of your check before adding it to your cart.";
	}

	const defaultOption = configDefaultOption(config);
	const currentValue = getValues(config.configurationKey);
	const defaultIsSelected = defaultOption?.value !== currentValue;

	const cardBodyClassName = cn({
		'pt-0': defaultIsSelected
	});

	if (getValues("ChooseSymbol-Business") === 'custom-logo-business') {
		return (
			<ReviewPanel title="Custom Logo Information"
			             cardBodyClassName={cardBodyClassName}>
				<div className="d-flex justify-content-center align-items-center symbol-selector-preview text-break">
					<div>
						If you choose to add a custom logo, please email your logo to <></>
						<a target="_blank" href="mailto:customerservice@checkworks.com">
							customerservice@checkworks.com
						</a>.
						Most image formats are accepted. Logo should be in Grayscale and file size should
						not exceed 2MB. Please reference your order number, and the name on the order.
					</div>
				</div>
			</ReviewPanel>
		);
	}

	return (
		<ReviewPanel title="Symbol Preview"
		             infoLabel={previewInfo()}
		             showInfoLabel={displayInfo}
		             cardBodyClassName={cardBodyClassName}>
			<div className="d-flex justify-content-center align-items-center symbol-selector-preview text-break">
				{
					defaultIsSelected ?
						<DisplayImage imageId={Number(currentValue)}/> :
						!hideNoSelectionMessage && NO_SELECTION_MESSAGE
				}
			</div>
		</ReviewPanel>
	)
}

const NO_SELECTION_MESSAGE = 'No symbol or monogram selected';
