import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

import checksforfree from '../src/brands/locales/en/checksforfree.json';
import checkworks from '../src/brands/locales/en/checkworks.json';
import fallback from '../src/brands/locales/en/fallback.json';

export enum Brand {
    Fallback = 'Fallback',
    ChecksForFree = 'ChecksForFree',
    CheckWorks = 'CheckWorks'
}

export const selectedBrand: Brand = getBrandFromHostname();

const resources = 
{
  Fallback: {
    translation: fallback
  },
  ChecksForFree: {
    translation: checksforfree
  },
  CheckWorks: {
    translation: checkworks
  }
};
const missingKeyHandler = (lngs: readonly string[], ns: string, key: string, fallbackValue: string, updateMissing: any, options: any) => {
    return "";
}

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        saveMissing: true,
        missingKeyHandler: missingKeyHandler,
        debug: false,
        lng: selectedBrand, // primary language (brand)
        fallbackLng: Brand.Fallback, // fallback in case the primary doesn't have this translation
        interpolation: {
            escapeValue: false,
            defaultVariables: { // these are available to ALL translations
              SiteName: getBrandFromHostname(),
              SITENAME: getBrandFromHostname().toUpperCase()
            }
        },
        backend: {
          backends: [
            HttpApi,
            resourcesToBackend(resources)
          ]
        }
    });

export default i18n;

export function getBrandFromHostname() {
    if (window.location.hostname.toLowerCase().indexOf('localhost') > -1) {
        return Brand.CheckWorks;
    }
    else if (window.location.hostname.toLowerCase().indexOf('ecommerce') > -1) {
        return Brand.CheckWorks;
    }
    else if (window.location.hostname.toLowerCase().indexOf('checksforfree') > -1) {
        return Brand.ChecksForFree;
    }
    else {
        return Brand.CheckWorks;
    }
}