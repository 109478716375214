import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router';
import {Layout} from './components/layout/Layout';
import {Home} from './components/Home';
import {AdminHome} from "./components/admin/AdminHome";
import {AdminLayout} from "./components/admin/AdminLayout";
import {ProductDetailsRoute} from "./components/product/details/ProductDetails";
import {OrderDetails} from "./components/admin/order/OrderDetails";
import {Reorder} from "./components/user/reorder/Reorder";
import {ConsumerDetailsView} from "./components/admin/customer/ConsumerDetailsView";
import {useMsal} from '@azure/msal-react';
import {EventMessage, EventType} from '@azure/msal-browser';

import './custom.css'
import {UserLogin} from './components/user/login/UserLogin';
import {NewOrder} from "./components/admin/order/NewOrder";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {CategoryView} from "./components/category/view/CategoryView";
import {Contact} from './components/pages/contact/Contact';
import {About} from './components/pages/about/About';
import {Faq} from './components/pages/faq/Faq';
import {ReturnPolicy} from './components/pages/returnPolicy/ReturnPolicy';
import {Guarantee} from './components/pages/guarantee/Guarantee';
import {PrivacyPolicy} from './components/pages/privacyPolicy/PrivacyPolicy';
import {CaliforniaProp} from './components/pages/californiaProp/CaliforniaProp';
import {CartReview} from "./components/cart/review/CartReview";
import {SignUp} from './components/user/signUp/SignUp';
import {ManageAdmins} from "./components/admin/admin/ManageAdmins";
import {EditAdmin} from "./components/admin/admin/EditAdmin";
import {FinancialInstitutionBrowse} from "./components/admin/financialInstitution/browse/FinancialInstitutionBrowse";
import {FinancialInstitutionDetails} from "./components/admin/financialInstitution/details/FinancialInstitutionDetails";
import {Unauthorized} from "./components/admin/unauthorized/Unauthorized";
import {useGetClaimsQuery} from "./app/apiSlice";
import {useAppSelector, useClaims} from "./app/hooks";
import {selectCurrentConsumerUser} from "./components/user/login/AuthenticationSlice";
import {TrackOrder} from './components/order/tracking/trackOrder/TrackOrder';
import {ConsumerOrderTracking} from './components/order/tracking/trackingTable/consumerOrderTracking/ConsumerOrderTracking';
import {OrderTracking} from './components/order/tracking/trackingTable/orderTracking/OrderTracking';
import {ADMIN, EDITING_ROLES, FINANCE, TEAM_LEAD, VIEW} from "./utils/Roles";
import {Spinner} from "reactstrap";
import {Checkout} from "./components/cart/checkout/Checkout";
import {UserProfile} from "./components/user/profile/UserProfile";
import {MicrEdit} from "./components/admin/micr/edit/MicrEdit";
import {OrderConfirmation} from "./components/order/OrderConfirmation";
import {RefundManagement} from "./components/admin/refund/management/RefundManagement";
import {ConsumerBrowse} from "./components/admin/customer/browse/ConsumerBrowse";
import {HoldsQueueBrowse} from "./components/admin/holdsQueue/browse/HoldsQueueBrowse";
import {ResetPassword} from "./components/user/resetPassword/ResetPassword";
import {PaymentMethodRollupReport} from "./components/reports/payment-method-rollup/PaymentMethodRollupReport";
import {OrderAchRejectReport} from "./components/reports/order-ach-reject/OrderAchRejectReport";
import {OrderItemDownloadReport} from "./components/reports/order-item-download/OrderItemDownloadReport";
import {OrderStatusReportReport} from "./components/reports/order-status-report/OrderStatusReportReport";
import {CustomerEmailDownloadReport} from "./components/reports/customer-email-download/CustomerEmailDownloadReport";
import {OrderDailySalesReport} from "./components/reports/order-daily-sales/OrderDailySalesReport";
import {OrderProductMixReport} from "./components/reports/order-product-mix/OrderProductMixReport";
import {ControlListBrowse} from "./components/admin/controlList/browse/ControlListBrowse";
import {EndOfMonthReportsBrowse} from "./components/admin/endOfMonthReports/browse/EndOfMonthReportsBrowse";
import {OrderMonthEndReport} from "./components/reports/order-month-end/OrderMonthEndReport";
import {LtvReport} from "./components/reports/ltv/LtvReport";
import {OrderRefundReportReport} from "./components/reports/order-refund-report/OrderRefundReportReport";
import {SourceCodeSalesReport} from "./components/reports/source-code-sales/SourceCodeSalesReport";
import StorefrontErrorBoundary from "./components/errorBoundary/storefront/StorefrontErrorBoundary";
import {getBrandFromHostname, selectedBrand} from "./i18n";
import AdminErrorBoundary from "./components/errorBoundary/admin/AdminErrorBoundary";
import {TaxReportReport} from './components/reports/tax-report/TaxReportReport';
import {TransactionReportReport} from './components/reports/transaction-report/TransactionReportReport';
import {RoyaltiesFeesReport} from './components/reports/royalties-fees/RoyaltiesFeesReport';
import {TaxProcessingPage} from './components/tax-processing/TaxProcessingPage';
import {ViewProcessedTaxFilesPage} from './components/tax-processing/ViewProcessedTaxFilesPage';
import {ViewHistoricalExportsPage} from "./components/tax-processing/ViewHistoricalExportsPage";
import claritasJavascript from "./assets/js/claritas";
import {getGoogleAnalyticsConfig} from "./utils/AnalyticsHelper";
import {ProductSearchView} from "./productSearch/view/ProductSearchView";
import {ProductConfigurationRoute} from "./productConfiguration/ProductConfigurationPage";
import {SIGNUP_PAGE_URL} from "./components/user/login/constants";
import {OrderLiabilityReport} from "./components/reports/order-liability/OrderLiabilityReport";
import {productConfigurationRoute, productDetailsRoute} from "./app/routes";
import {initializeDataDogRum, setAnalyticsProperty, setAnalyticsUser} from "./ops";

function RequireCustomerAuth({ children }: { children: JSX.Element }) {
	const consumer = useAppSelector(selectCurrentConsumerUser);
	let location = useLocation();

	if (!consumer) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

function RequireNoCustomerAuth({ children }: { children: JSX.Element }) {
	const consumer = useAppSelector(selectCurrentConsumerUser);

	if (consumer) {
		// Redirect them to the home page
		return <Navigate to="/" />;
	}

	return children;
}

function RequireRole({ children, roles }: { children: JSX.Element, roles: string[]}) {
	const { claimsAreLoaded, hasPermission } = useClaims();

	if (claimsAreLoaded)
		return hasPermission(roles) ? children : <Navigate to="/unauthorized" />;

	return <Spinner>Loading...</Spinner>;
}

const AdminRouter = () => {
	const { data: claims, refetch: refetchClaims, isError } = useGetClaimsQuery();
	const navigate = useNavigate();

	/**
	 * useMsal is hook that returns the PublicClientApplication instance,
	 * an array of all accounts currently signed in and an inProgress value
	 * that tells you what msal is currently doing. For more, visit:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
	 */
	const { instance } = useMsal();

	// redirect when we don't have roles or aren't logged in
	useEffect( () => {
		const activeAccount = instance.getActiveAccount();
		if (activeAccount !== null && (isError || (claims !== undefined && claims.length < 1))) {
			navigate('/unauthorized');
		}
		if (activeAccount) {
			setAnalyticsUser(activeAccount.username);
		}
	}, [claims, isError, instance.getActiveAccount()])

	useEffect(() => {
		setAnalyticsProperty('isAdmin', true);
	}, []);
	
	/**
	 * Using the event API, you can register an event callback that will do something when an event is emitted.
	 * When registering an event callback in a react component you will need to make sure you do 2 things.
	 * 1) The callback is registered only once
	 * 2) The callback is unregistered before the component unmounts.
	 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
	 */
	useEffect(() => {
		instance.handleRedirectPromise().then((authResult)=> {
			if (authResult !== null) {
				console.log(authResult);
			}
		});
		const callbackId = instance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_FAILURE) {
				navigate('/unauthorized');
			}

			if (event.eventType === EventType.HANDLE_REDIRECT_END || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
				instance.setActiveAccount(instance.getAllAccounts()[0] ?? null);
				refetchClaims();
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance]);

	return (
		<AdminLayout>
			<Routes>
				<Route path='/' element={<AdminErrorBoundary><AdminHome /></AdminErrorBoundary>} />

				<Route path='/refunds' element={<RequireRole roles={[ADMIN, TEAM_LEAD, FINANCE]}><AdminErrorBoundary><RefundManagement /></AdminErrorBoundary></RequireRole>}/>

				<Route path='/order/:orderId/:tab?' element={<AdminErrorBoundary><OrderDetails /></AdminErrorBoundary>}/>
				<Route path='/order/new' element={ <RequireRole roles={EDITING_ROLES}><AdminErrorBoundary><NewOrder/></AdminErrorBoundary></RequireRole>}/>

				<Route path='/holds' element={<AdminErrorBoundary><HoldsQueueBrowse /></AdminErrorBoundary>}/>

				<Route path='/consumers' element={<AdminErrorBoundary><ConsumerBrowse /></AdminErrorBoundary>}/>
				<Route path='/consumer/:consumerId' element={<AdminErrorBoundary><ConsumerDetailsView /></AdminErrorBoundary>}/>

				<Route path='/admins' element={<RequireRole roles={[ADMIN, TEAM_LEAD]}><AdminErrorBoundary><ManageAdmins /></AdminErrorBoundary></RequireRole>}/>
				<Route path='/admin/:adminId' element={<RequireRole roles={[ADMIN, TEAM_LEAD]}><AdminErrorBoundary><EditAdmin /></AdminErrorBoundary></RequireRole>}/>

				<Route path='/financialInstitutions' element={<AdminErrorBoundary><FinancialInstitutionBrowse /></AdminErrorBoundary>}/>
				<Route path='/financialInstitution/:paramFinancialInstitutionId' element={<AdminErrorBoundary><FinancialInstitutionDetails /></AdminErrorBoundary>}/>
				<Route path='/financialInstitution' element={<RequireRole roles={[ADMIN, TEAM_LEAD]}><AdminErrorBoundary><FinancialInstitutionDetails /></AdminErrorBoundary></RequireRole>}/>
				<Route path='/financialInstitution/micrEdit/:paramMicrFormatId' element={<AdminErrorBoundary><MicrEdit /></AdminErrorBoundary>}/>
				<Route path='/financialInstitution/:paramFinancialInstitutionId/micrEdit/' element={<AdminErrorBoundary><MicrEdit /></AdminErrorBoundary>}/>

				<Route path='/controlLists' element={<AdminErrorBoundary><ControlListBrowse /></AdminErrorBoundary>}/>

				<Route path='/taxProcessing' element={<AdminErrorBoundary><RequireRole roles={[ADMIN, FINANCE]}><TaxProcessingPage/></RequireRole></AdminErrorBoundary>}/>
				<Route path='/viewProcessedFiles' element={<AdminErrorBoundary><RequireRole roles={[ADMIN, FINANCE]}><ViewProcessedTaxFilesPage/></RequireRole></AdminErrorBoundary>}/>
				<Route path='/viewHistoricalExports' element={<AdminErrorBoundary><RequireRole roles={[ADMIN, FINANCE]}><ViewHistoricalExportsPage/></RequireRole></AdminErrorBoundary>}/>

				<Route path='/reports/paymentMethodRollup' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><PaymentMethodRollupReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/customerEmailDownload' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><CustomerEmailDownloadReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderMonthEnd' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><OrderMonthEndReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderAchReject' element={<RequireRole roles={[ADMIN, VIEW, FINANCE]}><AdminErrorBoundary><OrderAchRejectReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderItemDownload' element={<RequireRole roles={[ADMIN, VIEW, FINANCE]}><AdminErrorBoundary><OrderItemDownloadReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderStatusReport' element={<RequireRole roles={[ADMIN, VIEW, FINANCE]}><AdminErrorBoundary><OrderStatusReportReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/ltv' element={<RequireRole roles={[ADMIN, VIEW, FINANCE]}><AdminErrorBoundary><LtvReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderDailySales' element={<RequireRole roles={[ADMIN, VIEW]}><AdminErrorBoundary><OrderDailySalesReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderProductMix' element={<RequireRole roles={[ADMIN, VIEW]}><AdminErrorBoundary><OrderProductMixReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/sourceCodeSales' element={<RequireRole roles={[ADMIN]}><AdminErrorBoundary><SourceCodeSalesReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderRefundReport' element={<AdminErrorBoundary><OrderRefundReportReport/></AdminErrorBoundary>}/>
				<Route path='/reports/taxReport' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><TaxReportReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/transactionReport' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><TransactionReportReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/royaltiesFees' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><RoyaltiesFeesReport/></AdminErrorBoundary></RequireRole>}/>
				<Route path='/reports/orderLiability' element={<RequireRole roles={[ADMIN, FINANCE]}><AdminErrorBoundary><OrderLiabilityReport/></AdminErrorBoundary></RequireRole>}/>
				
				<Route path='/endOfMonthReports' element={<RequireRole roles={[ADMIN]}><AdminErrorBoundary><EndOfMonthReportsBrowse /></AdminErrorBoundary></RequireRole>}/>
			</Routes>
		</AdminLayout>
	)
}

/*
Routes are separated by StorefrontRouter & AdminRouter.
They contain their own Layouts
*/

const StorefrontRouter = () => {
	const consumer = useAppSelector(selectCurrentConsumerUser);
	
	const location = useLocation();
	useEffect(() => {
		const script = document.createElement('script');
		script.text = claritasJavascript;
		document.head.appendChild(script);
		
		return () => {
			document.head.removeChild(script);
		}
	}, [location]);

	useEffect(() => {
		if (consumer) {
			setAnalyticsUser(consumer.email);
		}
	}, [consumer]);

	useEffect(() => {
		setAnalyticsProperty('isConsumer', true);	
	}, []);
	
	return (
		<Layout>
			<Routes>
				<Route
					path={SIGNUP_PAGE_URL}
					element={
						<RequireNoCustomerAuth>
							<SignUp/>
						</RequireNoCustomerAuth>
					}
				/>
				<Route path='/' element={<StorefrontErrorBoundary><Home/></StorefrontErrorBoundary>}/>
				<Route path='category/:siteCategoryName'
					   element={<StorefrontErrorBoundary><CategoryView/></StorefrontErrorBoundary>}/>
				<Route path='category/:paramSiteCategoryName/product/:paramProductLookup'
					   element={<StorefrontErrorBoundary><ProductDetailsRoute/></StorefrontErrorBoundary>}/>
				<Route path='category/:paramSiteCategoryName/:paramProductLookup'
					   element={<StorefrontErrorBoundary><ProductDetailsRoute/></StorefrontErrorBoundary>}/>
				<Route path={productDetailsRoute.path}
					   element={<StorefrontErrorBoundary><ProductDetailsRoute/></StorefrontErrorBoundary>}/>
				<Route path={productConfigurationRoute.path}
					   element={<StorefrontErrorBoundary><ProductConfigurationRoute/></StorefrontErrorBoundary>}/>
				<Route path='/product/search' element={<StorefrontErrorBoundary><ProductSearchView/></StorefrontErrorBoundary>}/>
				<Route path='/cart' element={<StorefrontErrorBoundary><CartReview/></StorefrontErrorBoundary>}/>
				<Route path='/checkout' element={<StorefrontErrorBoundary><Checkout/></StorefrontErrorBoundary>}/>
				<Route path='/orderConfirmation'
					   element={<StorefrontErrorBoundary><OrderConfirmation/></StorefrontErrorBoundary>}/>
				<Route path="/login" element={<StorefrontErrorBoundary><UserLogin/></StorefrontErrorBoundary>}/>
				<Route path='/profile/:profileTab?/:profileTabRoute?' element={
					<StorefrontErrorBoundary>
						<RequireCustomerAuth>
							<UserProfile/>
						</RequireCustomerAuth>
					</StorefrontErrorBoundary>}/>
				<Route path="/track-order" element={<StorefrontErrorBoundary><TrackOrder/></StorefrontErrorBoundary>}/>
				<Route path='/reorder' element={<StorefrontErrorBoundary><Reorder/></StorefrontErrorBoundary>}/>
				<Route path="/contact-us" element={<StorefrontErrorBoundary><Contact/></StorefrontErrorBoundary>}/>
				<Route path="/about-us" element={<StorefrontErrorBoundary><About/></StorefrontErrorBoundary>}/>
				<Route path="/faq" element={<StorefrontErrorBoundary><Faq/></StorefrontErrorBoundary>}/>
				<Route path="/return-policy"
					   element={<StorefrontErrorBoundary><ReturnPolicy/></StorefrontErrorBoundary>}/>
				<Route path="/guarantee" element={<StorefrontErrorBoundary><Guarantee/></StorefrontErrorBoundary>}/>
				<Route path="/privacy-policy"
					   element={<StorefrontErrorBoundary><PrivacyPolicy/></StorefrontErrorBoundary>}/>
				<Route path="/ca-prop-65"
					   element={<StorefrontErrorBoundary><CaliforniaProp/></StorefrontErrorBoundary>}/>
				<Route path="/track-order/user-order-details"
					   element={<StorefrontErrorBoundary><ConsumerOrderTracking/></StorefrontErrorBoundary>}/>
				<Route path="/track-order/order-details/:orderId/:zip"
					   element={<StorefrontErrorBoundary><OrderTracking/></StorefrontErrorBoundary>}/>
				<Route path="/reset-password"
					   element={<StorefrontErrorBoundary><ResetPassword/></StorefrontErrorBoundary>}/>
				<Route path='*' element={<Navigate to='/' replace/>}/>
			</Routes>
		</Layout>
	);
}

const App = () => {
	// Append GA iframe tag just below opening body tag
	useEffect(() => {
		const gaConfig = getGoogleAnalyticsConfig(selectedBrand);
		const noscriptTag = document.createElement('noscript');
		const iframeTag = document.createElement('iframe');
		iframeTag.src = gaConfig.IFrameScriptSrc;
		noscriptTag.appendChild(iframeTag);
		document.body.insertBefore(noscriptTag, document.body.firstChild);
	}, []);

	return (
		<>
			<Routes>
				<Route path='admin/*' element={<AdminRouter />} />
				<Route path='/unauthorized' element={<Unauthorized />}/>
				<Route path='/*' element={<StorefrontRouter />} />
			</Routes>
		</>
	)
}

initializeDataDogRum({
	attributes: {
		siteName: getBrandFromHostname()
	}
})

export default App;
