import { SliceState } from "../../../utils/Utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAll, CriteriaAndPaging } from "./HoldFlagAPI";
import { RootState } from "../../../app/store";
import {HoldFlag} from "./HoldsFlag";
import {isEmpty} from "lodash";

export interface HoldFlagState {
    holdFlags?: HoldFlag[];
    status: SliceState;
    canGetMore: boolean;
}

const initialState: HoldFlagState = {
    holdFlags: undefined,
    status: 'idle',
    canGetMore: true,
};

export const fetchHoldFlags = createAsyncThunk(
    'holdFlags/fetchAll',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const fetchMoreHoldFlags = createAsyncThunk(
    'holdFlags/fetchMore',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const refetchAllHoldFlags = createAsyncThunk(
    'holdFlags/refetch',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const holdFlagSlice = createSlice({
    name: 'holdFlag',
    initialState,
    reducers: {
        changeSearch: state => {
            state.status = 'idle';
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchHoldFlags.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchHoldFlags.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.holdFlags = action.payload;
                state.canGetMore = !isEmpty(action.payload);
            })
            .addCase(fetchHoldFlags.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            })

            // fetch more
            .addCase(fetchMoreHoldFlags.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchMoreHoldFlags.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.length) state.holdFlags = state.holdFlags?.concat(action.payload);
                state.canGetMore = !isEmpty(action.payload);
            })
            .addCase(fetchMoreHoldFlags.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            })

            // refetch
            .addCase(refetchAllHoldFlags.pending, state => {
                state.status = 'loading'
                state.holdFlags = [];
            })
            .addCase(refetchAllHoldFlags.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.holdFlags = action.payload;
                state.canGetMore = !isEmpty(action.payload);
            })
            .addCase(refetchAllHoldFlags.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            });
    }
});

// Fetch hold flags
export const selectHoldFlags = (state: RootState) => state.holdFlags.holdFlags;
export const canFetchMoreHoldFlags = (state: RootState) => state.holdFlags.canGetMore;
export const isFetchingHoldFlags = (state: RootState) => state.holdFlags.status === 'loading' ||
    state.holdFlags.status === 'idle';

const { actions, reducer } = holdFlagSlice;
export const { changeSearch } = actions;
export default reducer;